<template>
   <div class="content_element container acc_faq">
      <div class="jobs_navigator_container main_form_faq">
         <div class="container_small">
            <vue-form :state="formstate" @submit.prevent="onSubmit" id="main-form">
               <div class="">
                  <a
                     href="#"
                     class="btn text-dark pl-0 bg-transparent mb-4"
                     v-if="step > 1 && step < 4"
                     @click.prevent="backButton"
                  >
                     <i class="ab-icon-arrow-left mr-2"></i>{{ __t("back") }}
                  </a>
               </div>
               <transition-group tag="div" class="h-100 prelative" :name="animation">
                  <div
                     class="h-100 d-flex flex-column prelative pb100"
                     key="step1"
                     v-if="step == 1"
                  >
                     <div
                        :class="
         anliegenError
          ? 'vf-field-pristine vf-field-invalid vf-field-submitted'
          : 'vf-field-untouched'
        "
                     >
                        <InputSelect
                           name="input_anliegen"
                           label="Ihr Anliegen"
                           :class="anliegenError ? 'vf-invalid' : ''"
                           placeholder="Ihr Anliegen"
                           inputId="input_anliegen"
                           v-model="model.topic"
                           :options="topics"
                           :required="true"
                        />

                        <div class="vf-error_message">
                           <span v-if="anliegenError">{{ errorMessage }}</span>
                        </div>
                     </div>

                     <validate tabindex="2">
                        <InputSelect
                           name="input_region"
                           label="Ihre Region"
                           placeholder="Ihr Anliegen"
                           inputId="input_region"
                           v-model="model.currentRegion"
                           :options="availableRegions"
                           :required="true"
                        />
                        <field-messages name="input_region" class="vf-error_message">
                           <template slot="required" slot-scope="state">
          <span v-if="state.$dirty || state.$submitted">{{
           __t("Name is a required field")
          }}</span>
                           </template>
                        </field-messages>
                     </validate>

                     <p class="mt-10 pl5" v-if="model.topic == 1 && model.currentRegion == 2">
                        <nl2br tag="strong" :text="__t('main_form.info_message_1')"/>
                     </p>

                     <p class="mt-10 pl5" v-if="model.topic == 7 && model.currentRegion == 2">
                        <nl2br tag="strong" :text="__t('main_form.info_message_2')"/>
                     </p>

                     <div
                        class="d-flex flex-wrap"
                        v-if="
         !(
          (model.topic == 7 && model.currentRegion == 2) ||
          (model.topic == 1 && model.currentRegion == 2)
         )
        "
                     >
                        <div class="flex-grow-1 pr30"></div>
                        <div class="">
                           <button tabindex="4" class="text-lowercase" type="submit">
                              {{ __t("next") }}
                           </button>
                        </div>
                     </div>
                  </div>

                  <div
                     class="h-100 d-flex flex-column prelative"
                     key="step2"
                     v-if="step == 2"
                  >
                     <Accordion
                        v-if="frequentlyQuestions.length"
                        :content="{ title: '', rows: frequentlyQuestions }"
                     />
                     <div class="">
                        <div class="row justify-content-center forms_fields">
                           <div class="col-12">
                              <FormRenderer
                                 :model.sync="model"
                                 :indexPrefix="'st_2'"
                                 :fields="step2Fields"
                              />
                           </div>
                        </div>
                     </div>

                     <div class="d-flex flex-wrap">
                        <div class="flex-grow-1 pr30"></div>
                        <div class="">
                           <button tabindex="4" class="text-lowercase" type="submit">
                              {{ __t("next") }}
                           </button>
                        </div>
                     </div>
                  </div>

                  <div
                     class="h-100 d-flex flex-column prelative"
                     key="step3"
                     v-if="step == 3"
                  >
                     <div class="row justify-content-center">
                        <div class="col-12">
                           <FormRenderer
                              :model.sync="model"
                              :indexPrefix="'st_3'"
                              :fields="step3Fields"
                           />

                           <InputFile
                              :name="'files'"
                              :label="__t('form.upload_attachments')"
                              :multiple="true"
                              :inputId="'input_files'"
                              @fileUploaded="filesUploaded"
                              :required="false"
                           ></InputFile>

                           <div class="d-flex flex-wrap">
                              <div class="flex-grow-1 pr30">
                                 <validate :tabindex="fields.length + 1">
                                    <InputCheckboxes
                                       name="confirmation_checkbox"
                                       :inputId="'confirmation_checkbox' + content.id"
                                       v-model="confirmationCheckbox"
                                       :options="[{ key: 'true', value: datenschutzTextValue }]"
                                       :required="true"
                                       :disabled="false"
                                    />
                                    <field-messages
                                       name="confirmation_checkbox"
                                       class="vf-error_message"
                                    >
                                       <template slot="required" slot-scope="state">
              <span v-if="state.$dirty || state.$submitted">{{
               __t("form.privacy_policy")
              }}</span>
                                       </template>
                                    </field-messages>
                                 </validate>
                              </div>
                              <div class="">
                                 <button type="submit">
                                    <template v-if="formSending">{{ __t("send") }} ...</template>
                                    <template v-else>{{ __t("send") }}</template>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div
                     class="h-100 d-flex flex-column prelative success-message"
                     key="step3"
                     v-if="step == 4"
                  >
                     <svg
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 50 50"
                        style="enable-background: new 0 0 50 50"
                        xml:space="preserve"
                     >
        <circle style="fill: #2e2e2e" cx="25" cy="25" r="25"/>
                        <polyline
                           style="
          fill: none;
          stroke: #fff;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
         "
                           points="
	38,15 22,33 12,25 "
                        />
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
       </svg>
                     <h2 class="mb30">{{ __t("form.successfully_sent_title") }}</h2>
                     <nl2br :text="__t('form.successfully_sent_desc')" :tag="'p'"/>

                     <a href="#" class="button-ok" @click.prevent="step = 1">{{ __t("form.successfully_sent_button")
                        }}</a>
                  </div>
               </transition-group>
            </vue-form>
         </div>
      </div>
   </div>
</template>

<script>
   import {InputSelect, InputCheckboxes} from "../../components/_form";
   import {mapState} from "vuex";
   import Accordion from "./Accordion";
   import FormRenderer from "../_common/FormRenderer";
   import InputFile from "../_form/InputFile";
   import nl2br from "../_common/Nl2br";

   export default {
      name: "MainForm",
      components: {
         InputFile,
         Accordion,
         FormRenderer,
         InputSelect,
         InputCheckboxes,
         nl2br,
      },
      props: {
         content: {
            type: Object,
            required: true,
         },
      },
      data() {
         return {
            animation: "animation-left",
            formstate: {},
            errorMessage: "Bitte füllen Sie dieses Pflichtfeld aus.",
            model: {
               topic: "",
               currentRegion: this.$store.state.region
                  ? this.$store.state.region.currentRegion.id
                  : 1,
            },
            confirmationCheckbox: null,
            formSending: false,
            step: 1,
            anliegenError: false,
            step3Fields: [
               {
                  is_visible: true,
                  is_required: false,
                  name: "salutation",
                  label: "Anrede",
                  placeholder: "Anrede wählen",
                  css_class: "col-12",
                  options: [
                     {
                        key: "Frau",
                        value: "Frau",
                     },
                     {
                        key: "Herr",
                        value: "Herr",
                     },
                     {
                        key: "Keine Anrede",
                        value: "Keine Anrede",
                     },
                  ],
                  _group: "dropdown",
               },
               {
                  is_visible: true,
                  is_required: true,
                  name: "first_name",
                  label: "Vorname",
                  placeholder: "Vorname eingeben",
                  css_class: "col-12",
                  _group: "text",
               },
               {
                  is_visible: true,
                  is_required: true,
                  name: "last_name",
                  label: "Name",
                  placeholder: "Name eingeben",
                  css_class: "col-12",
                  _group: "text",
               },
               {
                  is_visible: true,
                  is_required: true,
                  name: "street_house_number",
                  label: "Straße/Hausnr.",
                  placeholder: "Straße und Hausnummer eingeben",
                  css_class: "col-12",
                  _group: "text",
               },
               {
                  is_visible: true,
                  is_required: true,
                  name: "zip",
                  label: "PLZ",
                  placeholder: "12345",
                  css_class: "col-3 pr-0",
                  _group: "text",
               },
               {
                  is_visible: true,
                  is_required: true,
                  name: "place",
                  label: "Ort",
                  placeholder: "Ort eingeben",
                  css_class: "col-9",
                  _group: "text",
               },
               {
                  is_visible: true,
                  is_required: false,
                  name: "phone_number",
                  label: "Rufnummer",
                  placeholder: "+49 (0) ",
                  css_class: "col-12",
                  _group: "text",
               },
               {
                  is_visible: true,
                  is_required: true,
                  name: "email",
                  label: "E-Mail-Adresse",
                  placeholder: "E-Mail-Adresse",
                  css_class: "col-12",
                  _group: "email",
               },
               {
                  is_visible: true,
                  is_required: false,
                  name: "other_hints",
                  label: "Sonstiges/Hinweis",
                  placeholder: "Nachricht eingeben",
                  css_class: "col-12",
                  _group: "textarea",
               },
            ],
         };
      },
      computed: {
         ...mapState(["form", "region"]),
         fields() {
            return [];
         },
         step2Fields() {
            let result = [];
            this.form.config.topics.forEach((item) => {
               if (item.id == this.model.topic) {
                  result = item.questions;

                  if (this.model.currentRegion === 1 && item.id === 9) { //Mittedldeutschland && Gruppenanmeldung
                     result = item.questions.filter(function (value) {
                        return value.name !== 'group_type';
                     })
                  }
               }
            });

            return result;
         },
         datenschutzTextValue() {
            return '<a target="_blank" href="/datenschutz">Datenschutzbestimmungen</a> gelesen und akzeptiert';
         },
         topics() {
            let result = [];
            this.form.config.topics.forEach((item) => {
               result.push({
                  key: item.id,
                  value: item.title,
               });
            });

            return result;
         },
         frequentlyQuestions() {
            let result = [];
            this.form.config.faq.forEach((item) => {
               if (this.model.topic == item.form_topic_id) {
                  result.push(item);
               }
            });

            return result;
         },
         availableRegions: function () {
            const result = [];
            this.region.all.forEach((region) => {
               result.push({
                  key: region.id,
                  value: region.title,
               });
            });
            return result;
         },
      },
      methods: {
         filesUploaded(files) {
            this.model.files = files;
         },
         backButton() {
            if (this.step === 3 && this.step2Fields === null) this.step = this.step - 2;
            else this.step--;
         },
         stringToOptions(options) {
            let array = [];
            let optionsArray = options.split("\n");

            for (let i = 0; i < optionsArray.length; i++) {
               array.push({
                  key: optionsArray[i].trim(),
                  value: optionsArray[i].trim(),
               });
            }

            return array;
         },
         async onSubmit () {
            this.formstate._validate();
            let self = this;
            if (this.formstate.$valid && this.validate()) {
               if (this.step == 3) {
                  this.$store.commit("loading/setFullPageLoaderVisible", true);

                  let formData = new FormData();

                  Object.keys(self.model).forEach(function (key) {
                     if (key === "files" && self.model?.files?.length > 0) {
                        for (let i = 0; i < self.model.files.length; i++) {
                           formData.append("files[" + i + "]", self.model.files[i]);
                        }
                     } else {
                        formData.append(key, self.model[key]);
                     }
                  });

                  this.$store.dispatch("form/submitForm", formData).then(() => {
                     self.$store.dispatch("alert/success", {
                        title: this.__t("form.request"),
                        message: this.__t("form.sent_successfully"),
                     });
                     self.model = {};
                     self.confirmationCheckbox = null;
                     self.formstate._reset();
                     self.step = 4;
                     self.$store.commit("loading/setFullPageLoaderVisible", false);
                  });
               } else {
                  this.step = this.step + 1;

                  if (this.step === 2 && this.step2Fields === null) {
                     this.step = 3;
                  }

                  this.formstate._reset();
               }
            }
         },
         validate() {
            let isValid = true;
            if (this.model.topic === "") {
               this.anliegenError = true;
               isValid = false;
            } else {
               this.anliegenError = false;
            }

            return isValid;
         },
      },
      mounted() {
         this.$store.dispatch("form/getConfig");
         this.currentRegion = this.region.currentRegion.id;
      }
   }
</script>
